import BaseModel from "./BaseModel";

import OrganizationModel from "./OrganizationModel";
import UserModel from "./UserModel";
import LockerModel from "./LockerModel";
import StatusModel from "./StatusModel";
import ContainerAllocationModel from "./ContainerAllocationModel";

class AllocationModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;

    this.code = data.code;
    this.externalCode = data.externalCode;

    this.dateToAllocate = data.dateToAllocate;
    this.dateAllocated = data.dateAllocated;
    this.userAllocated = data.userAllocated;

    this.dateToRetrieve = data.dateToRetrieve;    
    this.dateMessageNotification = data.dateMessageNotification;
    this.messageSent = !!data.dateMessageNotification;

    this.dateEmailNotification = data.dateEmailNotification;
    this.emailSent = !!data.dateEmailNotification ?? false;

    this.qrCode = data.qrCode;

    this.organization = new OrganizationModel(data.organization);
    this.locker = new LockerModel(data.locker);
    this.status = new StatusModel(data.status);
    this.customer = data?.customer ? new UserModel(data?.customer) : null;

    this.containerAllocations =
      data.containerAllocations?.length > 0
        ? data.containerAllocations.map((i) => new ContainerAllocationModel(i))
        : [];
  }

  getContainerAllocationsToRetrieve() {
    const result = this.containerAllocations.filter((i) => i.inLocker);
    return result;
  }
}

export default AllocationModel;
